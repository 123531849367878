<template>
  <div class="columns no-margin">
    <div class="column no-padding is-full">
      <div class="card">
        <header class="card-header has-background-primary">
          <p class="card-header-title has-text-light">PENCAPAIAN NILAI</p>
        </header>
        <div class="card-content table-card-content">
          <b-field
            position="is-right"
            class="filter-field"
            grouped
            group-multiline
          >
            <generic-filter-select
              class="filter3"
              apiPath="/stase/tahun-options/"
              field="tahun"
              v-model="filterMap.tahun"
            ></generic-filter-select>
            <generic-filter-select
              class="filter3"
              apiPath="/programstudi/subdepartemen/"
              field="departemen"
              v-model="filterMap.sub_departemen"
            ></generic-filter-select>
            <cari-input class="filter3" v-model="filterMap.cari"></cari-input>
            <filter-button @apply-filter="applyFilter"></filter-button>
          </b-field>

          <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
          <div class="box-container">
            <small v-if="staseList.length > 0" class="page-info is-pulled-right"
              ><strong>{{ pagination.startRowNum }}</strong> -
              <strong>{{ pagination.startRowNum + staseList.length - 1 }}</strong>
              dari <strong>{{ pagination.total }}</strong></small
            >
            <div class="cleardiv"></div>
            <template v-if="isAdminBagian">
              <box
                v-for="stase in staseList"
                :key="stase.id"
                :title="stase.departemen"
                :subtitle1="`${stase.mulai} - ${stase.hingga}`"
                :subtitle2="stase.preceptor"
              >
                <template v-slot:date>
                  <b-upload
                    class="is-pulled-right"
                    :key="`${stase.id}_${fileCounter}`"
                    v-if="canUpload(stase.hingga)"
                    v-model="fl"
                    @input="() => upload(stase)"
                    accept=".xlsx"
                  >
                    <a title="Upload">
                      <b-icon icon="upload"></b-icon>
                    </a>
                  </b-upload>
                  <a
                    title="Download"
                    class="is-pulled-right"
                    @click.stop.prevent="download(stase)"
                  >
                    <b-icon icon="download" pack="fas" />
                  </a>
                  <router-link
                    title="Lihat"
                    class="is-pulled-right"
                    :to="{
                      name: 'pencapaian-nilai-detail',
                      params: { id: stase.id },
                    }"
                  >
                    <b-icon icon="eye" pack="fas" />
                  </router-link>
                </template>
              </box>
            </template>

            <template v-else>
              <box
                v-for="stase in staseList"
                :key="stase.id"
                :title="stase.departemen"
                :subtitle1="`${stase.mulai} - ${stase.hingga}`"
                :subtitle2="stase.preceptor"
                :detailUrl="{ name: 'pencapaian-nilai-detail', params: { id: stase.id } }"
              />
            </template>
            <box v-if="staseList.length == 0">
              <template v-slot:content>
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>Tidak ada data.</p>
                  </div>
                </section>
              </template>
            </box>
          </div>

          <!-- <b-table
            bordered
            striped
            hoverable
            :loading="loading"
            :data="staseList"
            :mobile-cards="false"
          >
            <b-table-column
              field="no"
              label="No"
              v-slot="props"
            >{{ pagination.startRowNum + props.index }}</b-table-column>
            <b-table-column field="departemen" label="Departemen" v-slot="props">
              <small>{{ props.row.mulai }} - {{ props.row.hingga }}</small>
              <br />
              <router-link
                :to="{ name: 'pencapaian-nilai-detail', params: {id: props.row.id} }"
              >{{ props.row.departemen }}</router-link>
              <br />
            </b-table-column>
            <b-table-column
              field="koordinator"
              label="Koordinator"
              v-slot="props"
            >{{ props.row.preceptor }}</b-table-column>
            <b-table-column field="aksi" label="Aksi" v-if="isAdminBagian" v-slot="props">
              <b-upload
                :key="`${props.row.id}_${fileCounter}`"
                v-if="canUpload(props.row.hingga)"
                v-model="fl"
                @input="() => upload(props.row)"
                accept=".xlsx"
              >
                <a title="Upload">
                  <b-icon icon="upload"></b-icon>
                </a>
              </b-upload>
              <a title="Download" class="margin-left" @click.stop.prevent="download(props.row)">
                <b-icon icon="download" pack="fas" />
              </a>
            </b-table-column>

            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>Tidak ada data.</p>
                </div>
              </section>
            </template>
          </b-table>-->

          <b-pagination
            v-if="pagination.showPagination"
            :total="pagination.total"
            :per-page="pagination.limit"
            :current.sync="pagination.page"
            order="is-centered"
            icon-pack="fas"
            @change="pageChange"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import APP_CONFIG from "@/apps/core/modules/config";
import NilaiExcel from "../modules/nilaiExcel";
import { parse } from "date-fns";
import { mapGetters } from "vuex";
import onlineCheckMixin from "@/apps/core/mixins/onlineCheckMixin";
import fetchActivatedMixin from "@/apps/core/mixins/fetchActivatedMixin";
import paginationMixin from "@/apps/core/mixins/paginationMixin";
import StaseList from "../models/pencNilaiList.js";

export default {
  name: "PencapaianNilaiList",
  components: {
    Box: () => import("@/apps/core/components/Box.vue"),
    CariInput: () => import("@/apps/core/components/CariInput.vue"),
    FilterButton: () => import("@/apps/core/components/FilterButton.vue"),
    GenericFilterSelect: () =>
      import("@/apps/core/components/GenericFilterSelect.vue"),
  },
  data() {
    this.staseListMdl = new StaseList();
    this.listMdl = this.staseListMdl; // bikin alias
    this.nilaiExcel = new NilaiExcel((staseId) => {
      this.$router.push({
        name: "pencapaian-nilai-detail",
        params: { id: staseId },
      });
      this.fl = null;
    });
    let obv = this.staseListMdl.getObservables();
    obv.fl = null;
    obv.fileCounter = 0;
    return obv;
  },
  computed: {
    ...mapGetters("accounts", ["isAdminBagian"]),
  },
  methods: {
    canUpload(dateStr) {
      const hingga = parse(dateStr, "dd/MM/yyyy", new Date());
      const now = new Date();
      const canUpload = now > hingga;
      return canUpload;
    },
    download(stase) {
      this.nilaiExcel.setStase(stase);
      this.nilaiExcel.exportFile();
    },
    upload(stase) {
      this.nilaiExcel.setStase(stase);
      const apiUrl = `${APP_CONFIG.baseAPIURL}/stase/${stase.id}/pencapaian/`;
      this.nilaiExcel.setApiUrl(apiUrl);
      this.nilaiExcel.importFile(this.fl);
      this.fileCounter++;
    },
  },
  mixins: [onlineCheckMixin, fetchActivatedMixin, paginationMixin],
};
</script>


<style scoped lang="scss">
@import "@/apps/core/assets/scss/listview.scss";

.ol-style {
  margin-left: 15px;
  padding: 5px;
}

.margin-left {
  margin-left: 15px;
}
/* ::v-deep .kel-filter {
  width: 12rem;
} */
</style>
