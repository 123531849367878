import APP_CONFIG from "@/apps/core/modules/config";
import Excel from "exceljs";
import ExcelBase from "@/apps/core/modules/excelBase";
import axios from "axios";
import { ToastProgrammatic as Toast } from "buefy";


class NilaiExcel extends ExcelBase {
  constructor(onSaved) {
    super();
    this.nilaiSummary = [];
    this.stase = null;
    this.nilaiJsonArr = []; // reset ketika file null
    this.pspdJsonArr = []; // reset ketika file null
    this.token = null;
    this.onSaved = onSaved;
  }

  setStase(stase) {
    this.stase = stase;
  }

  writeWorkbook() {
    let url = `${APP_CONFIG.baseAPIURL}/stase/${this.stase.id}/`;
    axios
      .get(url, { params: { download: "xlsx" } })
      .then(response => {
        this.token = response.data.data.token;
        this._writeToExcel(response.data.data.data_set);
      })
      .catch(() => {
        this.reset();
        Toast.open("File gagal di download.");
      });
  }

  async _writeToExcel(data_set) {
    let num = 0;
    for (const [npm, data] of Object.entries(data_set)) {
      num++;
      let row = [num, data.nama, npm];
      this.nilaiJsonArr.push(row);
      this.pspdJsonArr.push(data);
    }
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet("Nilai");
    const cell_A1 = worksheet.getCell("A1");
    const cell_A2 = worksheet.getCell("A2");
    const cell_A3 = worksheet.getCell("A3");
    worksheet.getRow(1).hidden = true;

    // Modify/Add individual cell
    cell_A1.value = this.token;
    cell_A1.dataValidation = {
      type: "whole",
      allowBlank: false,
      operator: "equal",
      formulae: [this.token],
      showErrorMessage: true,
      error: "Data tidak boleh diubah."
    };
    cell_A2.value = "UNIVERSITAS PADJADJARAN";
    cell_A2.style = { font: { size: 20, bold: true } };
    worksheet.getRow(2).height = 27;
    const subJudul = `${this.stase.departemen}: ${this.stase.mulai} - ${this.stase.hingga}`;
    cell_A3.value = subJudul;
    cell_A3.style = { font: { size: 16 } };
    worksheet.getRow(3).height = 18;

    // merge a range of cells
    worksheet.mergeCells("A6:N6");
    worksheet.getCell("A6").value = "NILAI UTAMA";

    const metodeMap = {
      D7: "BST",
      E7: "CRS",
      F7: "CSS",
      G7: "MINICEX",
      H7: "UJIAN",
      D8: "%",
      E8: "%",
      F8: "%",
      G8: "%",
      H8: "%"
    };
    for (const [cell, val] of Object.entries(metodeMap)) {
      worksheet.getCell(cell).value = val;
      if (val == "%") {
        worksheet.getCell(cell).note = 'Sesuaikan presentase berdasarkan Departemen.';
      }
    }

    const colWidthMap = { A: 5, B: 30, C: 15, L: 30, M: 30, N: 15 };
    for (const [col, width] of Object.entries(colWidthMap)) {
      worksheet.getColumn(col).width = width;
    }

    const cols = [
      "No",
      "Nama",
      "NPM",
      "FINAL",
      "HURUF MUTU",
      "PASS/FAIL",
      "PENGUJI",
      "PRESEPTOR",
      "KASUS UJIAN"
    ];
    ["A", "B", "C", "I", "J", "K", "L", "M", "N"].forEach((col, i) => {
      worksheet.mergeCells(`${col}7:${col}8`);
      worksheet.getCell(`${col}8`).value = cols[i];
    });

    const startRowNum = 6;
    const headerRowCount = 3;
    const headerColCount = 14;

    for (const row_num of Array(headerRowCount).keys()) {
      let num = row_num + startRowNum;
      worksheet.getRow(num).alignment = {
        wrapText: true,
        vertical: "middle",
        horizontal: "center"
      };
      for (const cidx of Array(14).keys()) {
        worksheet.getCell(num, cidx + 1).fill = {
          type: "pattern",
          pattern: "lightGray"
        };
      }
    }

    const passedValidation = {
      type: "list",
      allowBlank: false,
      operator: "between",
      showInputMessage: true,
      formulae: ['"PASS,FAIL"'],
      promptTitle: "Kelulusan",
      showErrorMessage: true,
      error: "Opsi tidak tersedia.",
      prompt: "Silakan pilih opsi yang tersedia."
    };
    for (const row_num of Array(
      headerRowCount + this.nilaiJsonArr.length
    ).keys()) {
      for (const cidx of Array(headerColCount).keys()) {
        if (row_num < this.nilaiJsonArr.length && cidx < 3) {
          const dataCell = worksheet.getCell(
            row_num + startRowNum + headerRowCount,
            cidx + 1
          );
          const val = this.nilaiJsonArr[row_num][cidx];
          dataCell.value = val;
          dataCell.dataValidation = {
            type: "whole",
            allowBlank: false,
            operator: "equal",
            formulae: [val],
            showErrorMessage: true,
            error: "Data tidak boleh diubah."
          };
        }
        if (row_num < this.nilaiJsonArr.length && cidx == 10) {
          worksheet.getCell(
            row_num + startRowNum + headerRowCount,
            cidx + 1
          ).dataValidation = passedValidation;
        }
        worksheet.getCell(row_num + startRowNum, cidx + 1).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
      }
    }

    const lastRowNum =
      startRowNum + headerRowCount + this.nilaiJsonArr.length;
    worksheet.getCell(`J${lastRowNum + 1}`).value = "Mengetahui,";
    worksheet.getCell(
      `J${lastRowNum + 2}`
    ).value = `Koord. PSPD Departemen ${this.stase.departemen},`;
    worksheet.getCell(`J${lastRowNum + 8}`).value = this.stase.preceptor;
    worksheet.getCell(`J${lastRowNum + 9}`).value = "NIP.";

    worksheet.getCell("J7").note = 'Contoh : A, B+, C.';

    for (const nilai of this.pspdJsonArr) {
      const wsPspd = workbook.addWorksheet(nilai.npm);
      const colWMap = { A: 5, B: 30, E: 30, F: 30 };
      for (const [col, width] of Object.entries(colWMap)) {
        wsPspd.getColumn(col).width = width;
      }

      let pspdStartRowNum = 6;
      const pspdHeaderRowCount = 2;
      let pspdHeaderColCount = 6;

      const cellSubJudul = wsPspd.getCell("A1");
      cellSubJudul.value = subJudul;
      cellSubJudul.style = { font: { size: 16 } };
      wsPspd.getRow(1).height = 18;

      wsPspd.getCell("A2").value = nilai.nama;
      wsPspd.getCell("A3").value = nilai.npm;
      // merge a range of cells
      wsPspd.mergeCells("A6:F6");

      let rowDataKi = [];
      let numKi = 0;
      for (let ki of nilai.nilai_ki) {
        numKi++;
        rowDataKi.push([
          numKi,
          ki.judul,
          ki.jenis,
          ki.nilai,
          ki.penguji.join(", "),
          ki.pembimbing.join(", ")
        ]);
      }

      const kiColMap = {
        A: "No",
        B: "Judul",
        C: "Jenis",
        D: "Nilai",
        E: "Penguji",
        F: "Pembimbing"
      };

      for (const row_num of Array(pspdHeaderRowCount).keys()) {
        let num = row_num + pspdStartRowNum;
        wsPspd.getRow(num).alignment = {
          wrapText: true,
          horizontal: "center"
        };
        for (const [col, val] of Object.entries(kiColMap)) {
          wsPspd.getCell(`${col}${num}`).value = val;
          wsPspd.getCell(`${col}${num}`).fill = {
            type: "pattern",
            pattern: "lightGray"
          };
        }
      }

      for (const row_num of Array(
        pspdHeaderRowCount + rowDataKi.length
      ).keys()) {
        for (const cidx of Array(pspdHeaderColCount).keys()) {
          if (row_num < rowDataKi.length) {
            wsPspd.getCell(
              row_num + pspdStartRowNum + pspdHeaderRowCount,
              cidx + 1
            ).value = rowDataKi[row_num][cidx];
          }
          wsPspd.getCell(row_num + pspdStartRowNum, cidx + 1).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" }
          };
        }
      }
      wsPspd.getCell("A6").value = "Data Karya Ilmiah";

      const sidx =
        pspdStartRowNum + pspdHeaderRowCount + nilai.nilai_ki.length + 3;
      // merge a range of cells
      wsPspd.mergeCells(`A${sidx}:E${sidx}`);

      let rowDataUjian = [];
      let numUjian = 0;
      for (let ujian of nilai.nilai_ujian) {
        numUjian++;
        rowDataUjian.push([
          numUjian,
          ujian.judul,
          ujian.ujian,
          ujian.nilai,
          ujian.penguji.join(", ")
        ]);
      }

      const ujianColMap = {
        A: "No",
        B: "Judul",
        C: "Ujian",
        D: "Nilai",
        E: "Penguji"
      };
      pspdStartRowNum = sidx;
      pspdHeaderColCount = 5;

      for (const row_num of Array(
        pspdHeaderRowCount + rowDataUjian.length
      ).keys()) {
        for (const cidx of Array(pspdHeaderColCount).keys()) {
          if (row_num < rowDataUjian.length) {
            wsPspd.getCell(
              row_num + pspdStartRowNum + pspdHeaderRowCount,
              cidx + 1
            ).value = rowDataUjian[row_num][cidx];
          }
          wsPspd.getCell(row_num + pspdStartRowNum, cidx + 1).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" }
          };
        }
      }

      for (const row_num of Array(pspdHeaderRowCount).keys()) {
        // worksheet.getRow(row_num).font = { bold: true };
        let num = row_num + pspdStartRowNum;
        wsPspd.getRow(num).alignment = {
          wrapText: true,
          horizontal: "center"
        };
        for (const [col, val] of Object.entries(ujianColMap)) {
          wsPspd.getCell(`${col}${num}`).value = val;
          wsPspd.getCell(`${col}${num}`).fill = {
            type: "pattern",
            pattern: "lightGray"
          };
        }
      }
      wsPspd.getCell(`A${sidx}`).value = "Data Ujian";
    }
    const buffer = await workbook.xlsx.writeBuffer();
    const url = window.URL.createObjectURL(
      new Blob([buffer],
        { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' }
      )
    );
    const link = document.createElement("a");
    link.href = url;
    this.filename = `${this.stase.departemen}_${this.getCurrentDatetime()}.xlsx`
      .split(" ")
      .join("_");
    link.setAttribute("download", this.filename); //or any other extension
    // link.download = this.filename;
    document.body.appendChild(link);
    link.click();
    // window.URL.revokeObjectURL(url);
  }

  async load(event) {
    try {
      const workbook = new Excel.Workbook();
      await workbook.xlsx.load(event.target.result);
      const nilaiWs = workbook.getWorksheet("Nilai");
      if (!nilaiWs) {
        Toast.open({ message: "Format excel salah. Sheet Nilai tidak ditemukan.", duration: 3000 });
        return;
      }
      const token = nilaiWs.getCell("A1").value;
      this.payLoadData = { token: token, data_set: {} };
      let _data = this.payLoadData;
      let _nilaiSummary = this.nilaiSummary;
      let num = 0;
      nilaiWs.eachRow({ includeEmpty: false }, function (row, rowNumber) {
        const npm = nilaiWs.getCell(rowNumber, 3).value;
        if (rowNumber >= 9 && npm) {
          num++;
          const nama = nilaiWs.getCell(rowNumber, 2).value;
          let nilai = nilaiWs.getCell(rowNumber, 9).value;
          nilai = nilai && nilai.formula ? nilai.result : nilai;
          const predikat = nilaiWs.getCell(rowNumber, 10).value;
          const pass = nilaiWs.getCell(rowNumber, 11).value;
          let lulus = "";
          if (pass) {
            if (pass.toUpperCase() == "PASS") {
              lulus = "Ya";
            } else if (pass.toUpperCase() == "FAIL") {
              lulus = "Tidak";
            }
          }
          _data['data_set'][npm] = { nilai: nilai, predikat: predikat, lulus: lulus };
          _nilaiSummary.push([num, nama, npm, nilai, predikat, lulus]);
        }
      });
      this.patch(response => {
        if (response.data.data != {}) {
          this.uploadFile(response.data.data.dokumen_stase_id);
        }
      });
    } catch {
      Toast.open("Format excel tidak sesuai.");
    }
  }

  getCurrentDatetime() {
    const currentdt = new Date();
    const mm = currentdt.getMonth() + 1; // getMonth() is zero-based
    const dd = currentdt.getDate();
    const hh = currentdt.getHours();
    const mi = currentdt.getMinutes();

    return [
      currentdt.getFullYear(),
      (mm > 9 ? "" : "0") + mm,
      (dd > 9 ? "" : "0") + dd,
      (hh > 9 ? "" : "0") + hh,
      (mi > 9 ? "" : "0") + mi
    ].join("");
  }

  uploadFile(dokId) {
    // aws
    let awsUrl = `${APP_CONFIG.baseAPIURL}/aws/`;
    axios
      .get(awsUrl, { params: { app_name: "dokumenstase", filename: this.fl.name } })
      .then(response => {
        let resp_data = JSON.parse(JSON.stringify(response.data));
        let s3 = resp_data.s3.dok_detail;
        let fields = s3.fields;
        const formData = new FormData();
        for (const [key, val] of Object.entries(fields)) {
          formData.append(key, val);
        }
        formData.append("file", this.fl, this.fl.name);
        axios
          .post(s3.url, formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then(() => {
            let dokDetailUrl = s3.url + encodeURI(fields.key);
            this.uploadSummaryFile(
              resp_data.s3.dok_summary,
              dokDetailUrl,
              dokId
            );
          })
          .catch(() => {
            this.reset();
            Toast.open("File gagal di upload.");
          });
      })
      .catch(() => {
        this.reset();
        Toast.open("File gagal di upload.");
      });
  }

  async uploadSummaryFile(s3, dokDetailUrl, dokId) {
    let fields = s3.fields;
    const formData = new FormData();
    for (const [key, val] of Object.entries(fields)) {
      formData.append(key, val);
    }

    const workbook = this._writeSummaryExcel();
    const buffer = await workbook.xlsx.writeBuffer();
    const fileOfBlob = new File([buffer], s3.url + fields.key);
    formData.append("file", fileOfBlob);

    axios
      .post(s3.url, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(() => {
        let data = {
          dok_detail: dokDetailUrl,
          dok_summary: s3.url + encodeURI(fields.key)
        };
        this.saveDokumenStase(data, dokId);
      })
      .catch(() => {
        this.reset();
        Toast.open("File gagal di upload.");
      });
  }
  _writeSummaryExcel() {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet("Nilai");
    const cell_A2 = worksheet.getCell("A2");
    const cell_A3 = worksheet.getCell("A3");
    worksheet.getRow(7).height = 25;
    worksheet.getColumn("A").width = 5;
    worksheet.getColumn("B").width = 30;
    worksheet.getColumn("C").width = 15;

    // Modify/Add individual cell
    cell_A2.value = "UNIVERSITAS PADJADJARAN";
    cell_A2.style = { font: { size: 20, bold: true } };
    worksheet.getRow(2).height = 27;
    const subJudul = `${this.stase.departemen}: ${this.stase.mulai} - ${this.stase.hingga}`;
    cell_A3.value = subJudul;
    cell_A3.style = { font: { size: 16 } };
    worksheet.getRow(3).height = 18;

    // merge a range of cells
    worksheet.mergeCells("A6:F6");
    worksheet.getCell("A6").value = "NILAI UTAMA";
    const cols = ["No", "Nama", "NPM", "FINAL", "HURUF MUTU", "PASS/FAIL"];
    ["A", "B", "C", "D", "E", "F"].forEach((col, i) => {
      worksheet.getCell(`${col}7`).value = cols[i];
    });

    const startRowNum = 6;
    const headerRowCount = 2;
    const headerColCount = 6;

    for (const row_num of Array(headerRowCount).keys()) {
      let num = row_num + startRowNum;
      worksheet.getRow(num).alignment = {
        wrapText: true,
        vertical: "middle",
        horizontal: "center"
      };
      for (const cidx of Array(headerColCount).keys()) {
        worksheet.getCell(num, cidx + 1).fill = {
          type: "pattern",
          pattern: "lightGray"
        };
      }
    }

    for (const row_num of Array(
      headerRowCount + this.nilaiSummary.length
    ).keys()) {
      for (const cidx of Array(headerColCount).keys()) {
        if (row_num < this.nilaiSummary.length) {
          let val = this.nilaiSummary[row_num][cidx];
          if (cidx == 5) {
            val = val == "Ya" ? "PASS" : "FAIL";
          }
          worksheet.getCell(
            row_num + startRowNum + headerRowCount,
            cidx + 1
          ).value = val;
        }
        worksheet.getCell(row_num + startRowNum, cidx + 1).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
      }
    }

    const lastRowNum =
      startRowNum + headerRowCount + this.nilaiSummary.length;
    worksheet.getCell(`D${lastRowNum + 1}`).value = "Mengetahui,";
    worksheet.getCell(
      `D${lastRowNum + 2}`
    ).value = `Koord. PSPD Departemen ${this.stase.departemen},`;
    worksheet.getCell(`D${lastRowNum + 8}`).value = this.stase.preceptor;
    worksheet.getCell(`D${lastRowNum + 9}`).value = "NIP.";
    return workbook;
  }

  saveDokumenStase(data, dokId) {
    const postUrl = `${APP_CONFIG.baseAPIURL}/stase/dokumen/${dokId}/`;
    axios
      .patch(postUrl, data)
      .then(response => {
        this.fl = null;
        this.reset();
        if (this.onSaved) this.onSaved(response.data.stase_id);
      })
      .catch(error => {
        this.reset();
        if (error.response.status === 400) {
          // Perlu handle 403 juga
          Toast.open("File gagal disimpan.");
        }
      });
  }

  reset() {
    super.reset();
    this.nilaiJsonArr = []; // reset ketika file null
    this.pspdJsonArr = []; // reset ketika file null
    // this.stase = null;
    this.token = null;
    this.nilaiSummary = [];
  }

  loadMessage(errorMessages) {
    let message = '';
    if (errorMessages.errors) {
      message = '<strong>Data Nilai:</strong>';
      message += '<ul style="list-style: square; padding: 15px;">';
      for (const error of errorMessages.errors) {
        let errorText = '';
        for (const [key, val] of Object.entries(error.errors)) {
          if (Array.isArray(val)) {
            errorText += `<p>${key}: ${val[0]}</p>`;
          } else {
            for (const [field, msg] of Object.entries(val)) {
              errorText += `<p>${field}: ${msg[0]}</p>`;
            }
          }
        }
        message += `<li :key="${error.nama}">No Urut ke ${error.row_num} ${error.nama} :<br>${errorText}</li>`;
      }
      message += '</ul>';
    }
    return message;
  }
}

export default NilaiExcel;